var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"asg-money-input-content"},[(!_vm.readOnly)?_c('div',{staticClass:"input-content",class:[
    _vm.type === 'textarea' ? 'el-textarea' : 'el-input',
    _vm.inputSize ? 'el-input--' + _vm.inputSize : '',
    {
      'is-disabled': _vm.disabled,
      'el-input-group': _vm.$slots.prepend || _vm.$slots.append,
      'el-input-group--append': _vm.$slots.append,
      'el-input-group--prepend': _vm.$slots.prepend,
      'el-input--prefix': _vm.$slots.prefix || _vm.prefixIcon,
      'el-input--suffix': _vm.$slots.suffix || _vm.suffixIcon
    }
  ],on:{"mouseenter":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false}}},[(_vm.type !== 'textarea')?[(_vm.$slots.prepend)?_c('div',{staticClass:"el-input-group__prepend",attrs:{"tabindex":"0"}},[_vm._t("prepend")],2):_vm._e(),(_vm.type !== 'textarea')?_c('input',_vm._b({ref:"input",staticClass:"el-input__inner",attrs:{"autocomplete":_vm.autoComplete,"aria-label":_vm.label,"oninput":_vm.oninput},domProps:{"value":_vm.formatValue},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}},'input',_vm.$props,false)):_vm._e(),(_vm.$slots.prefix || _vm.prefixIcon)?_c('span',{staticClass:"el-input__prefix",style:(_vm.prefixOffset)},[_vm._t("prefix"),(_vm.prefixIcon)?_c('i',{staticClass:"el-input__icon",class:_vm.prefixIcon}):_vm._e()],2):_vm._e(),(
        _vm.$slots.suffix ||
          _vm.suffixIcon ||
          _vm.showClear ||
          (_vm.validateState && _vm.needStatusIcon)
      )?_c('span',{staticClass:"el-input__suffix",style:(_vm.suffixOffset)},[_c('span',{staticClass:"el-input__suffix-inner"},[(!_vm.showClear)?[_vm._t("suffix"),(_vm.suffixIcon)?_c('i',{staticClass:"el-input__icon",class:_vm.suffixIcon}):_vm._e()]:_c('i',{staticClass:"el-input__icon el-input__clear el-icon-circle-close",on:{"click":_vm.clear}})],2),(_vm.validateState)?_c('i',{staticClass:"el-input__icon",class:['el-input__validateIcon', _vm.validateIcon]}):_vm._e()]):_vm._e(),(_vm.$slots.append)?_c('div',{staticClass:"el-input-group__append"},[_vm._t("append",function(){return [_vm._v("元")]})],2):_vm._e()]:_c('textarea',_vm._b({ref:"textarea",staticClass:"el-textarea__inner",style:(_vm.textareaStyle),attrs:{"aria-label":_vm.label},domProps:{"value":_vm.formatValue},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}},'textarea',_vm.$props,false))],2):_vm._e(),_c('span',{staticClass:"chi-text"},[_vm._v(_vm._s(_vm._f("convertMoney")(_vm.value)))])])
}
var staticRenderFns = []

export { render, staticRenderFns }