import { render, staticRenderFns } from "./AsgMoneyInputDoc.vue?vue&type=template&id=6600701e"
import script from "./AsgMoneyInputDoc.vue?vue&type=script&lang=js"
export * from "./AsgMoneyInputDoc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports