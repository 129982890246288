import { render, staticRenderFns } from "./AsgHighTableDoc.vue?vue&type=template&id=d2f3b6b6&scoped=true"
import script from "./AsgHighTableDoc.vue?vue&type=script&lang=js"
export * from "./AsgHighTableDoc.vue?vue&type=script&lang=js"
import style0 from "./AsgHighTableDoc.vue?vue&type=style&index=0&id=d2f3b6b6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2f3b6b6",
  null
  
)

export default component.exports